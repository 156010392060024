import { Link } from 'react-router-dom';
import Icon from '../icon.jpg';
import { useAuth } from '../AuthContext';
import { GoGear  } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa";

const Navbar = () => {

    const { logout, isLoggedIn, role } = useAuth();
    const navigate = useNavigate();


    const handleLogoutButton = () => {
        logout();
        //setRole('');
        navigate('/');
    }

    return ( 
        <nav className="navbar bg-light sticky-top">
            <div className="container-xxl">
                    <Link to='/'>
                        <img src={Icon} alt="Logo" width="40" height="40" className="rounded" />
                        <span className="mx-2 logo-text text-dark fs-4 align-text-center"></span>
                    </Link>
                <div className="justify-content-end"> 
                    {!isLoggedIn && (
                    <>
                        <Link to='/login'>
                            <button className="btn ">Zaloguj się</button>      
                        </Link>
                        <Link to='/register'>
                        <button className="btn btn-outline-dark">Zarejestruj się</button>      
                        </Link>
                    </>
                    )}
                    {isLoggedIn && (
                        <>
                            <Link to='/dashboard'>
                                <button className="btn btn-outline-dark" ><FaUser /></button>  
                            </Link>
                            <Link to='/create-survey'>
                            <button className="btn btn-outline-dark mx-2" >+</button>  
                            </Link>
                        
                            <Link to='/'>
                                <button className="btn btn-outline-dark" onClick={handleLogoutButton}>Wyloguj się</button>      
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </nav>
     );
}
 
export default Navbar;