import { Button, Modal } from 'react-bootstrap';

const ConfirmDialog = ({ 
  showModal, 
  handleClose, 
  confirmAction, 
  confirmMessage, 
  confirmButtonLabel = 'Potwierdź', 
  cancelButtonLabel = 'Anuluj' 
}) => {

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <span>{confirmMessage}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" onClick={handleClose}>
          {cancelButtonLabel}
        </Button>
        <Button variant="outline-danger" onClick={confirmAction}>
          {confirmButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDialog;
