import React from 'react';
import { Link } from 'react-router-dom';

const ThanksForVote = () => {
  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-md-8 text-center">
          <h2 className="display-1">Dziękujemy!</h2>
          <h2 className="mt-4">Twój głos został oddany.</h2>
          <p className="lead">Dziękujemy za udział w naszej ankiecie!</p>
          <Link to="/">
            <button className="btn btn-primary">Powrót do strony głównej</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ThanksForVote;
