import React from 'react';
import { useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import useFetch from '../useFetch';
import axios from 'axios';
import config from '../config';


const Vote = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const { votingID } = useParams();
    const navigate = useNavigate();
    const { data:pollData , isPending, error } = useFetch(`${config.API_BASE_URL}/ankiety/api/getvotedata.php?pollId="${votingID}"`);


  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleCheckboxChange = (option) => {
    setSelectedOption(option);
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try{
        const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/postvote.php`, {selectedOption});
        if(response.data.success){
            navigate('/thanks-for-vote');
        }else{
            // setErrorMessage("Nie udało się dodać ankiety! Spróbuj ponownie");
        }
    } catch(error){
        console.error('Błąd: ', error);
    }


    console.log('Wybrana odpowiedź:', selectedOption);
  };

    return (  
        <div className="container-sm mt-5 rounded bg-light">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    {error && <div className="alert alert-danger">{error}</div>}
                    {isPending && <div className="text-center">Ładowanie...</div>}
                    {pollData && pollData.data && pollData.data.length > 0 && (
                        <>
                            <h2 className="text-left my-4">{pollData.data[0].tresc}</h2>
                            <form onSubmit={handleSubmit} className="d-grid">
                                <div className="form-group">
                                    <div className="form-floating mb-3">
                                            {pollData.data.map((item, index) => (                                                
                                                <div className="col-12" key={index}>
                                                <input type="checkbox" 
                                                className="form-control btn-check" 
                                                id={`btn-check-${item.answer_id}-${index}`} 
                                                autoComplete="off"
                                                checked={selectedOption === item.answer_id}
                                                onChange={() => handleCheckboxChange(item.answer_id)}
                                                />
                                                <label className="btn btn-outline-dark d-block mb-2 text-start" htmlFor={`btn-check-${item.answer}-${index}`}  onClick={() => handleOptionClick(item.answer_id)}>{item.answer}</label>
                                            </div>
                                            ))}
                                    </div>
                                    <button className="btn btn-primary mb-3" type="submit">Prześlij</button>
                                </div>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
 
export default Vote;