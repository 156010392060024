import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);
  const [token, setToken ] = useState('');
  const [username, setUsername] = useState();


  useEffect(() => {
      const isLoggedInCookie = Cookies.get('isLoggedIn');
      const jwtCookie = Cookies.get('jwtToken');
      
      if(isLoggedInCookie){
        const decodedToken = jwtDecode(jwtCookie);
        setToken(jwtCookie);
        setIsLoggedIn(true);
        setRole(decodedToken.role);
        setUsername(decodedToken.username);
      }
      setLoading(false); 
  },[isLoggedIn]);

  const login = () => {
    setIsLoggedIn(true);  
    Cookies.set('isLoggedIn', true);
  };

  const logout = () => {
    setIsLoggedIn(false);
    Cookies.remove('isLoggedIn');
    Cookies.remove('jwtToken');
    setRole('');
  };
  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, login, logout, role, setRole, loading, token, username }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
