import { useState, useEffect, useCallback  } from 'react';
import axios from 'axios';

const useFetch = (url, token) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        const fetchData = async () => {
            try {
                const response = await axios.get(url, {
                    headers: {
                      'Authorization': `Bearer ${token}`
                    },
                    cancelToken: source.token
                  });
                setData(response.data);
                setIsLoading(false);
                setError(null);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Zapytanie anulowane');
                } else {
                    setIsLoading(false);
                    setError('Wystąpił błąd podczas pobierania danych!');
                }
            }
        };

        fetchData();

        return () => {
            source.cancel('Operacja anulowana');
        };
    }, [url, token]);


    return { data, isLoading, error };
}

export default useFetch;
