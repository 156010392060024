import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import useFetch from '../useFetch';
import ConfirmDelete from './ConfirmDelete';
import axios from 'axios';
import config from '../config'; 

const UserManagement = () => {
    const [showModal, setShowModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState();
    const [userId, setUserId] = useState();
    const [selectedRole, setSelectedRole] = useState();
    const [actionType, setActionType] = useState();
    const [users, setUsers] = useState([]);
    
    const { token, username } = useAuth();
    const { data: usersData, isPending, error } = useFetch(`${config.API_BASE_URL}/ankiety/api/getallusers.php`, token);

    useEffect(() => {
        if (usersData && usersData.data) {
            setUsers(usersData.data);
        }
    }, [usersData]);

    const handleClose = () => {
        setUserToDelete();
        setSelectedRole();
        setActionType();
        setShowModal(false);
    }

    const handleDeleteUser = (username, userId) => {
        setActionType('delete');
        setUserToDelete(username);
        setUserId(userId);
        setShowModal(true);
    };

    const confirmDeleteUser = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/delete-user.php`, {
                userId: userId
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.success) {
                console.log('usunięto użytkownika');
                setUsers(users.filter(user => user.id !== userId));
            } else {
                console.log("cos nie tak");
            }
        } catch (error) {
            console.error('Wystąpił błąd: ', error);
        }
        handleClose();
    };

    const handleChangeRole = (user, event, userId) => {
        setActionType('roleChange');
        setUserToDelete(user);
        setUserId(userId);
        setSelectedRole(event);
        setShowModal(true);
    }

    const confirmRoleChange = async () => {
        try {
            console.log(userId);
            const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/change-role.php`, {
                userId: userId,
                role: selectedRole
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.success) {
                console.log(`Zmieniono rolę użytkownika: ${userToDelete} na ${selectedRole}`);
                setUsers(users.map(user => user.username === userToDelete ? { ...user, role: selectedRole } : user));
            } else {
                console.log("cos nie tak");
            }
        } catch (error) {
            console.error('Wystąpił błąd: ', error);
        }
        handleClose();
    }

    const confirmAction = actionType === 'delete' ? confirmDeleteUser : confirmRoleChange;
    const confirmMessage = actionType === 'delete' ? `Czy na pewno chcesz usunąć użytkownika: ${userToDelete}`
        : `Czy na pewno chcesz zmienić rolę użytkownika: ${userToDelete} na: ${selectedRole}`;

    return (
        <div className="container mt-5">
            <h3>Użytkownicy</h3>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Nazwa użytkownika</th>
                        <th>Rola</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(user => (
                        <tr key={user.id}>
                            {user.username !== username && (
                                <>
                                    <td className="col-1">{user.username}</td>
                                    <td className="col-1">
                                        <select className="form-select" aria-label="Default select example" value={user.role || ''} onChange={(e) => handleChangeRole(user.username, e.target.value, user.id)}>
                                            <option value='admin'>admin</option>
                                            <option value="user">user</option>
                                        </select>
                                    </td>
                                    <td className="col-1">
                                        <button type="button" className="btn btn-outline-danger" onClick={() => handleDeleteUser(user.username, user.id)}>Usuń</button>
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            {showModal && <ConfirmDelete
                showModal={showModal}
                handleClose={handleClose}
                confirmDeleteUser={confirmDeleteUser}
                confirmAction={confirmAction}
                confirmMessage={confirmMessage}
                confirmButtonLabel={actionType === 'delete' ? 'Usuń' : 'Zapisz'}
                cancelButtonLabel="Anuluj"
            />}
        </div>
    );
}

export default UserManagement;
