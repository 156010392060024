import React, { useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../config';

const ResetPassword = () => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const location = useLocation();
    const getQueryParam = (paramName) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(paramName);
    };

    const token = getQueryParam('token');

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (password !== confirmPassword) {
            setErrorMessage('Hasła się nie zgadzają');
            return;
        }

        try {
            const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/reset-password.php`, { token, password });

            if (response.data.success) {
                setSuccessMessage(response.data.message);
                setTimeout(() => {
                    navigate('/');
                }, 2000); 
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error('Błąd resetowania hasła: ', error);
            setErrorMessage('Wystąpił błąd podczas resetowania hasła');
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card shadow p-4">
                        <div className="card-body">
                            <h1 className="card-title text-center mb-4">Resetowanie hasła</h1>
                            <form onSubmit={handlePasswordReset}>
                                <div className="form-group">
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="floatingPassword" 
                                            placeholder="Nowe hasło"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <label htmlFor="floatingPassword">Nowe hasło</label>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input 
                                            type="password" 
                                            className="form-control" 
                                            id="floatingConfirmPassword" 
                                            placeholder="Potwierdź nowe hasło"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        <label htmlFor="floatingConfirmPassword">Potwierdź nowe hasło</label>
                                    </div>
                                    <button type="submit" className="btn btn-primary btn-block mt-3">
                                        Zresetuj hasło
                                    </button>
                                </div>
                                {successMessage && (
                                    <div className="alert alert-success mt-2 mb-0">
                                        {successMessage}
                                    </div>
                                )}
                                {errorMessage && (
                                    <div className="alert alert-danger mt-2 mb-0">
                                        {errorMessage}
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
 
export default ResetPassword;