import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
import Navbar from "./compontents/Navbar";
import Home from "./compontents/Home";
import Login from "./compontents/Login";
import Register from './compontents/Register';
import CreatePoll from './compontents/CreatePoll';
import PollResult from './compontents/PollResult';
import Footer from './compontents/Fotter';
import UserManagement from './compontents/UserManagement';
import NotFound from './compontents/NotFound';
import AccountSettings from './compontents/AccountSettings';
import Vote from './compontents/Vote';
import PrivateRoutes from './compontents/PrivateRoutes';
import ProtectedRouteAdmin from './compontents/ProtectedRouteAdmin';
import SurveysManagement from './compontents/SurveysManagement';
import Dashboard from './compontents/Dashboard';
import MySurveys from './compontents/MySurveys';
import ForgotPassword from './compontents/ForgotPassword';
import ResetPassword from './compontents/ResetPassword';
import PublicRoutes from './compontents/PublicRoutes';
import Notification from './compontents/Notification';
import { useState } from 'react';
import ThanksForVote from './compontents/ThanksForVote';

function App() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const showNotification = (message) => {
    setToastMessage(message);
    setShowToast(true);
    console.log(message);
  };



  return (
      <Router>
          <div className="App">
            <Navbar />
            <div className="container-fluid">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/vote/:votingID" element={<Vote />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/thanks-for-vote" element={<ThanksForVote />} />

                <Route element={<PublicRoutes />}>
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                </Route>
                
                <Route element={<ProtectedRouteAdmin />}>
                  <Route path="/usermanagement" element={<UserManagement />} /> 
                  <Route path="/surveys-management" element={<SurveysManagement />} />
                </Route>

                <Route element={<PrivateRoutes />}>
                  <Route path="/accountsettings" element={<AccountSettings /> } />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/pollresult/:pollId" element={<PollResult showNotification={showNotification} />}  />
                  <Route path="/create-survey" element={<CreatePoll />} />
                  <Route path="/my-surveys" element={<MySurveys />} />
                </Route> 
                
              </Routes>
            </div>
            <Footer />
                <Notification show={showToast} onClose={() => setShowToast(false)} message={toastMessage} />
          </div>
      </Router>
  );
}

export default App;
