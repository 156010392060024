import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../AuthContext'

const ProtectedRouteAdmin = () => {
    const {isLoggedIn, loading, role} = useAuth();

    if (loading) {
        return <div>Ładowanie...</div>;
    }
    return isLoggedIn && role === 'admin' ? <Outlet/> : <Navigate to="/" />;
}

export default ProtectedRouteAdmin;