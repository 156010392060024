import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../AuthContext'

const PublicRoutes = () => {
    const {isLoggedIn, loading} = useAuth();

    if (loading) {
        return <div>Ładowanie...</div>;
    }
    return !isLoggedIn ? <Outlet/> : <Navigate to="/dashboard" />;
}

export default PublicRoutes;