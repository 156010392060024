import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import config from '../config';

const CreatePoll = () => {
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState(['', '']);
  const { token } = useAuth();

  const navigate = useNavigate();

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleAddField = () => {
    if (answers.length < 8) { 
      setAnswers([...answers, '']);
    }
  };

  const handleRemoveField = (index) => {
    const newAnswers = [...answers];
    newAnswers.splice(index, 1);
    setAnswers(newAnswers);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      //const response = await apiService.post('login.php', {username, password});
      const pollId = uuidv4();
      const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/createpoll.php`, {question, answers, pollId, token},
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
      );
      //console.log(response);
      if(response.data.success){
        navigate('/pollresult/'+pollId);
      }else{
          console.log("Wystapil blad podczas dodawania ankiety");
      }
  } catch(error){
      console.error('Błąd logowania: ', error);
  }

    //console.log('Wysłano pytanie:', question);
    //console.log('Odpowiedzi:', answers);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Utwórz nową ankietę</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder='Zadaj pytanie'
                    required
                  />
                </div>
                {answers.map((answer, index) => (
                  <div key={index} className="form-group d-flex mt-2">
                    <input
                      type="text"
                      className="form-control mr-2"
                      id={`answer${index + 1}`}
                      value={answer}
                      onChange={(e) => handleAnswerChange(index, e.target.value)}
                      placeholder= {`Odpowiedź ${index + 1}`}
                      required
                    />
                    {index > 1 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger mx-1"
                        onClick={() => handleRemoveField(index)}
                      >
                        -
                      </button>
                    )}
                  </div>
                ))}
                <div className="text-left mb-3 mt-1">
                  <div className="mb-2">
                    {answers.length < 8 && (
                      <button type="button" className="btn btn-outline-dark mr-2 mt-2" onClick={handleAddField}>
                        +
                      </button>
                    )}
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Utwórz ankietę
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );   
}
 
export default CreatePoll;