import { Outlet, Navigate } from 'react-router-dom'
import { useAuth } from '../AuthContext'

const PrivateRoutes = () => {
    const {isLoggedIn, loading} = useAuth();

    if (loading) {
        return <div>Ładowanie...</div>;
    }
    

    return isLoggedIn ? <Outlet/> : <Navigate to="/" />;
}

export default PrivateRoutes;