import axios from "axios";
import { useState } from "react";
import { Link } from 'react-router-dom';
import config from "../config";

const ForgotPassword = () => {
    
    const [email, setEmail] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState('');


    const HandleEmailSend = async (e) => {
        e.preventDefault();
        try{
            //const response = await apiService.post('login.php', {username, password});
            const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/forgot-password.php`, {email});
            if(response.data.success){
                setSuccessMessage(response.data.message);
            }else{
                setErrorMessage(response.data.message);
            }
        } catch(error){
            console.error('Błąd logowania: ', error);
        }
    }
    
    return ( 
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card shadow p-4">
                        <div className="card-body">
                            <div className="mb-4 text-sm text-gray-600 dark:text-gray-400">
                                Jeśli nie pamiętasz hasła podaj adres e-mail, na który zostanie wysłany link do zresetowania hasła
                            </div>
                        <form>
                            <div className="form-group">
                                <div className="form-floating mb-3">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="floatingInput" 
                                        placeholder="name@example.com"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <label htmlFor="floatingInput">Podaj adres e-mail</label>
                                </div>
                                <button type="submit" className="mb-4 btn btn-primary" onClick={HandleEmailSend}>
                                    Wyślij link na e-mail
                                </button>
                            </div>
                            {successMessage && (
                                <div className="alert alert-success mt-2 mb-0">
                                    {successMessage}
                                </div>
                            )}
                            {errorMessage && (
                                <div className="alert alert-danger mt-2 mb-0">
                                    {errorMessage}
                                </div>
                            )}
                        </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-md-6">
                    <Link to='/register'>
                        <p className="text-center">Nie masz konta? <b>Zarejestruj się</b></p>
                    </Link>
                </div>
            </div>
        </div>
     );
}
 
export default ForgotPassword;