import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import useFetch from '../useFetch';
import { useAuth } from '../AuthContext';
import config from '../config';

const SurveysManagement = () => {
    const { token, username } = useAuth();
    const [currentPage, setCurrentPage] = useState(1);
    const [pollsPerPage] = useState(15);
    const { data: pollData, isPending, error } = useFetch(`${config.API_BASE_URL}/ankiety/api/getallpolls.php`, token);
  
    useEffect(() => {
      setCurrentPage(1);
    }, [pollData]);


    const currentPolls = pollData && pollData.data ? pollData.data : [];
  
    const indexOfLastPoll = currentPage * pollsPerPage;
    const indexOfFirstPoll = indexOfLastPoll - pollsPerPage;
    const currentPollsOnPage = currentPolls.slice(indexOfFirstPoll, indexOfLastPoll);
  
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
  
    return (
      <div className="container mt-5">
        <h3>Lista ankiet</h3>
        {error && <div className="alert alert-danger">{error}</div>}
        {isPending && <div className="text-center">Ładowanie...</div>}
        <ul className="list-group mt-3">
          {currentPollsOnPage.map((poll) => (
            <li key={poll.id} className="list-group-item d-flex justify-content-between align-items-center">
              <Link to={`/pollresult/${poll.id}`}>{poll.tresc}</Link>
              <span className="badge bg-primary rounded-pill">{poll.creation_date}</span>
            </li>
          ))}
        </ul>
        <Pagination className="mt-4 justify-content-center">
          {Array.from({ length: Math.ceil(currentPolls.length / pollsPerPage) }, (_, i) => (
            <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => paginate(i + 1)}>
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    );
  };
  
  export default SurveysManagement;
  
