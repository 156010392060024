const Footer = () => {
    return ( 
        <footer className="footer mt-auto py-3">
            <div className="container text-center">
                <span className="text-muted"></span>
            </div>
        </footer>
     );
}
 
export default Footer;