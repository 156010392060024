import React, { useState } from "react";
import axios from "axios";
import { useAuth } from '../AuthContext';
import config from '../config'; // Importuj plik konfiguracyjny

const AccountSettings = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useAuth();

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage('Nowe hasło i jego potwierdzenie nie zgadzają się.');
      return;
    }

    try {
      const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/change-password.php`, {
        currentPassword: password,
        newPassword: newPassword
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.success) {
        setSuccessMessage(response.data.message);
        setPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
        setErrorMessage('');
      } else {
        setErrorMessage(response.data.message);
      }
    } catch (error) {
      setErrorMessage('Wystąpił błąd podczas zmiany hasła.');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Zmień hasło</h2>
      <div className="mb-3 row">
        <label htmlFor="currentPassword" className="col-sm-2 col-form-label">Obecne hasło:</label>
        <div className="col-sm-3">
          <input type="password" className="form-control" id="currentPassword" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="newPassword" className="col-sm-2 col-form-label">Nowe hasło:</label>
        <div className="col-sm-3">
          <input type="password" className="form-control" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
      </div>
      <div className="mb-3 row">
        <label htmlFor="confirmNewPassword" className="col-sm-2 col-form-label">Potwierdź nowe hasło:</label>
        <div className="col-sm-3">
          <input type="password" className="form-control" id="confirmNewPassword" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
        </div>
      </div>
      <button className="btn btn-primary" onClick={handleChangePassword}>Zmień hasło</button>

      {errorMessage && <div className="text-danger mt-3">{errorMessage}</div>}
      {successMessage && <div className="text-success mt-3">{successMessage}</div>}
    </div>
  );
}

export default AccountSettings;
