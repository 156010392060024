import { Link } from "react-router-dom";

const NotFound = () => {
    return ( 
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h1 className="display-1">404</h1>
            <h2>Nie znaleziono strony</h2>
            <p className="lead">Wygląda na to, że próbujesz uzyskać dostęp do strony, która nie istnieje.</p>
            <Link to="/">
              <button className="btn btn-primary">Powrót do strony głównej</button>
            </Link>
          </div>
        </div>
      </div>
     );
}
 
export default NotFound;