import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { jwtDecode } from 'jwt-decode';
import config from '../config';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState();
    const { login, isLoggedIn, role, setRole} = useAuth();
    const navigate = useNavigate();


    useEffect(() => {
        if (isLoggedIn) {
            //console.log(role, isLoggedIn);
            navigate('/dashboard');
        }
    }, [isLoggedIn]);

    const handleLogin = async (e) =>{
        e.preventDefault();
        try{
            //const response = await apiService.post('login.php', {username, password});
            const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/login.php`, {username, password});
            if(response.data.success){
                login();
                const token = response.data.token;
                const decodedToken = jwtDecode(token)
                setRole(decodedToken.role);
                document.cookie = `jwtToken=${token}; path=/`;
            }else{
                setErrorMessage("Nieprawidłowe dane logowania");
            }
        } catch(error){
            console.error('Błąd logowania: ', error);
        }
    }

    return ( 
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card shadow">
                        <div className="card-body">
                        <h1 className="card-title text-center mb-4">Logowanie</h1>
                        <form>
                            <div className="form-group">
                                <div className="form-floating mb-3">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="floatingInput" 
                                        placeholder="name@example.com"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                    <label htmlFor="floatingInput">Nazwa użytkownika</label>
                                    </div>
                                    <div className="form-floating">
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="floatingPassword" 
                                        placeholder="Password" 
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <label htmlFor="floatingPassword">Hasło</label>
                                </div>
                            </div>
                            <div className="mt-2 form-check">
                            </div>
                            <div className={`alert alert-danger ${errorMessage ? 'd-block' : 'd-none'} mt-2 mb-0`}>
                                {errorMessage}
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <button type="submit" className="btn btn-primary" onClick={handleLogin}>
                                    Zaloguj
                                </button>
                                <Link to='/forgot-password'>
                                    <p className="mb-0 text-dark" >Nie pamiętam hasła</p>
                                </Link>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-md-6">
                    <Link to='/register'>
                        <p className="text-center">Nie masz konta? <b>Zarejestruj się</b></p>
                    </Link>
                </div>
            </div>
        </div>
     );
}
 
export default Login;