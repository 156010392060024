import React, { useState, useEffect, useRef  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetch from '../useFetch';
import { IoIosLink } from "react-icons/io";
import { useAuth } from '../AuthContext';
import { Link } from "react-router-dom";
import { Pie, Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS,
  BarElement,
  ArcElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
} from 'chart.js';
import ConfirmDelete from './ConfirmDelete';
import axios from 'axios';
import config from '../config';
 

ChartJS.register(ArcElement, BarElement, PointElement, LineElement, LineController,  Tooltip, Legend, CategoryScale, LinearScale);

const PollResult = ({ showNotification }) => {
  const { pollId } = useParams();
  const { token } = useAuth();

  const { data:pollData , isPending, error } = useFetch(`${config.API_BASE_URL}/ankiety/api/getpolldata.php?pollId="${pollId}"`, token);

  const pieChartRef = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [chartType, setChartType] = useState('pie');
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    if (pollData && pollData.data) {
      const labels = pollData.data.map(item => item.answer);
      const data = pollData.data.map(item => item.totalAnswers);
      setChartData({
        labels: labels,
        datasets: [{
          label: false,
          data: data,
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#4BC0C0',
            '#9966FF',
            '#FF9F40'
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#4BC0C0',
            '#9966FF',
            '#FF9F40'
          ]
        }]
      });

    }
  }, [pollData, pollId]);

  const handleCopyLink = () => {
    let currentUrl = window.location.href;
    currentUrl = currentUrl.replace('pollresult', 'vote');
    navigator.clipboard.writeText(currentUrl);
  };

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleDeleteSurvey = () => {
    setShowModal(true);
  }
  const handleClose = () =>{
    setShowModal(false);
  }
  
  const confirmDeleteSurvey = async (e) =>{
    e.preventDefault();
    try{
          //const response = await apiService.post('login.php', {username, password});
          const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/delete-survey.php`, {
            pollId: pollId
          }, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if(response.data.success){
            showNotification('Ankieta została pomyślnie usunięta.');
            navigate('/dashboard');
          }else{
              console.log("cos nie tak");
          }
          console.log(pollId);
    } catch(error){
        console.error('Wystąpił błąd: ', error);
    }

    console.log("Twoja ankieta została usunięta");
    handleClose();
  }
  const confirmAction = confirmDeleteSurvey;

  if (pollData && pollData.success === false && pollData.message.includes('Brak uprawnień')) {
    return (
      <div className="container-sm mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="alert alert-danger">Brak uprawnień do przeglądania wyników tej ankiety 😞</div>
          </div>
          <div className="col-md-8">
            <Link to="/">
                <button className="btn btn-primary">Powrót do strony głównej</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }else if(pollData && pollData.success === false && pollData.message.includes('token autoryzacyjny')){
    return(
      <div className="container-sm mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="alert alert-danger">Wyniki ankiety są dostępne tylko dla osoby tworzącej ankietę!</div>
          </div>
          <div className="col-md-8">
            <Link to="/">
                <button className="btn btn-primary">Powrót do strony głównej</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }else if(pollData && pollData.success === false && pollData.message.includes('Nie znaleziono ankiety o podanym ID')){
    return(
      <div className="container-sm mt-5">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="alert alert-danger">Nie znaleziono ankiety</div>
          </div>
          <div className="col-md-8">
            <Link to="/">
                <button className="btn btn-primary">Powrót do strony głównej</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-sm mt-4">

      <div className="rounded p-4 bg-light text-center border">
        <div className="row justify-content py-1 px-sm-5">
          <div className="col-md-5 text-start text-md-start">

          {error && <div className="alert alert-danger">{error}</div>}
            {isPending && <div className="text-center">Ładowanie...</div>}
            {pollData && pollData.data && pollData.data.length > 0 && (
              <div>
                <h3 className="mb-4">Wyniki ankiety:</h3>
                <h6>{pollData.data[0].tresc}?</h6>
                <ul>
                  {pollData.data.map((item, index) => (
                    <li key={index}>{item.answer}: <b>{item.totalAnswers}</b></li>
                  ))}
                </ul>
                <p>Łączna liczba głosów: <b>{pollData.data[0].totalSum}</b> </p>
                <p>Data utworzenia: <b>{pollData.data[0].creation_date}</b></p>
              </div>
            )}
            <button className="btn btn-dark my-4 " title='Skopiuj link do ankiety' onClick={handleCopyLink}><IoIosLink /></button>
            <button className="btn btn-outline-danger mx-2" onClick={handleDeleteSurvey}>Usuń ankietę</button>
          </div>
          <div className="col-md-5 text-center">
              <div className="form-group mb-4  text-start">
                <label htmlFor="chart TypeSelect" className="form-label">Wybierz typ wykresu:</label>
                <select id="chartTypeSelect" className="form-select" value={chartType} onChange={handleChartTypeChange}>
                  <option value="pie">Kołowy</option>
                  <option value="bar">Słupkowy</option>
                  <option value="line">Liniowy</option>
                </select>
              </div>

            {chartData && (
              
                <div className="chart-container">                
                  {chartType === 'pie' && <Pie ref={pieChartRef} data={chartData}  
                      options={{
                        responsive: true
                      }}
                  />}
                  {chartType === 'bar' && <Bar ref={pieChartRef} data={chartData} 
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            stepSize: 1
                          }
                        }
                      },
                      plugins: {
                        legend: {
                          display: false
                        },
                      },
                    }}
                  
                  />}
                  {chartType === 'line' && <Line ref={pieChartRef} data={chartData} 
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            stepSize: 1
                          }
                        }
                      },
                      plugins: {
                        legend: {
                          display: false, 
                        },
                      },
                    }}
                  />} 
                </div>
              
            )}
            {showModal && (
              <ConfirmDelete 
                showModal={showModal} 
                handleClose={handleClose} 
                confirmAction={confirmAction}
                confirmMessage={`Czy na pewno chcesz usunąć ankietę?`}
              />
            )}
          
          </div>
        </div>
      </div>
    </div>
  );
}
 
export default PollResult;