import { Link } from "react-router-dom";
import axios from 'axios';
import { useState, useEffect } from "react";
import { useAuth } from '../AuthContext';
import { jwtDecode }  from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import config from "../config";

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const { login, isLoggedIn, setRole } = useAuth();
    const [errorMessage, setErrorMessage] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn]);

    const validatePassword = (password) => {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
        return password.length >= minLength && hasUpperCase && hasLowerCase && hasSpecialChar;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUsernameError(false);
        setPasswordError(false);
        setConfirmPasswordError(false);
        setEmailError(false);

        if (!validatePassword(password)) {
            setErrorMessage("Hasło musi mieć co najmniej 8 znaków, zawierać dużą literę, małą literę i znak specjalny");
            setPasswordError(true);
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage("Hasła się nie zgadzają");
            setPasswordError(true);
            setConfirmPasswordError(true);
            return;
        }

        if (!username) setUsernameError(true);
        if (!password) setPasswordError(true);
        if (!confirmPassword) setConfirmPasswordError(true);
        if (!email) setEmailError(true);

        if (!username || !password || !confirmPassword || !email) {
            setErrorMessage("Uzupełnij wymagane pola");
            return;
        }

        try {
            const response = await axios.post(`${config.API_BASE_URL}/ankiety/api/register.php`, { username, password, email });
            if (response.data.success) {
                const token = response.data.token;
                const decodedToken = jwtDecode(token);
                setRole(decodedToken.role);
                login();
                document.cookie = `jwtToken=${token}; path=/`;
            } else {
                setErrorMessage(response.data.message || "Rejestracja nie powiodła się");
            }
        } catch (error) {
            console.error('Błąd rejestracji: ', error);
            setErrorMessage('Błąd serwera. Spróbuj ponownie później.');
        }
    };

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="card shadow">
                        <div className="card-body">
                            <h2 className="card-title text-center mb-4">Rejestracja</h2>
                           
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="username">Nazwa użytkownika</label>
                                    <input
                                        type="text"
                                        className={`form-control ${usernameError ? 'is-invalid' : ''}`}
                                        id="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Hasło</label>
                                    <input
                                        type="password"
                                        className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmPassword">Powtórz Hasło</label>
                                    <input
                                        type="password"
                                        className={`form-control ${confirmPasswordError ? 'is-invalid' : ''}`}
                                        id="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                                {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
                                <button type="submit" className="btn btn-primary btn-block mt-3">
                                    Zarejestruj się
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-md-6">
                    <Link to='/login'>
                        <p className="text-center">Masz już konto? <b>Zaloguj się</b></p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Register;
