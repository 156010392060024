import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const Notification = ({ show, onClose, message }) => {
    return (
        <ToastContainer position="top-center" className="p-3">
      <Toast show={show} onClose={onClose} delay={3000} autohide>
        <Toast.Header>
          <strong className="me-auto">Powiadomienie</strong>
          <small>Teraz</small>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
      );
}
 
export default Notification;