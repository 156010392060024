import Carousel from 'react-bootstrap/Carousel';
import myImage from '../1.png';
import myImage2 from '../2.png';
import myImage3 from '../3.png';

const Home = () => {

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-12">
          <div className="p-4 bg-light rounded border">
            <div className="row">
              <div className="col-md-6">
                <div className="p-3 border bg-white rounded">
                  <h2>Witamy w naszej aplikacji do ankiet</h2>
                  <p>
                    Nasza aplikacja do ankiet umożliwia Ci szybkie tworzenie, personalizowanie i udostępnianie ankiet w kilka chwil. Dzięki intuicyjnemu interfejsowi i bogatym funkcjom analizy danych, będziesz mógł zgłębiać opinie swoich respondentów i wyciągać cenne wnioski.
                  </p>
                  <p>
                    Niezależnie od tego, czy jesteś profesjonalistą, nauczycielem, badaczem czy przedsiębiorcą, nasze narzędzie dostosuje się do Twoich potrzeb. Skorzystaj z licznych szablonów, automatycznych raportów i integracji z innymi narzędziami, aby usprawnić proces zbierania danych.
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-md-0 mt-3">
                <Carousel indicators={true} controls={true} interval={3000}>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 rounded border"
                      src={myImage}
                      alt="First slide"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 rounded border"
                      src={myImage2}
                      alt="Second slide"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100 rounded border"
                      src={myImage3}
                      alt="Third slide"
                    />
                    <Carousel.Caption>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="p-4 bg-light rounded border">
            <h2>Dlaczego warto wybrać naszą aplikację?</h2>
            <ul>
              <li><strong>Szybkość i wygoda:</strong> Twórz i udostępniaj ankiety w kilka minut.</li>
              <li><strong>Personalizacja:</strong> Dostosuj ankiety do swoich potrzeb i wymagań.</li>
              <li><strong>Bezpieczeństwo:</strong> Twoje dane są bezpieczne dzięki pełnej zgodności z RODO.</li>
              <li><strong>Wsparcie:</strong> Oferujemy wsparcie techniczne na każdym etapie korzystania z naszej aplikacji.</li>
              <li><strong>Analiza danych:</strong> Zaawansowane narzędzia analityczne pozwalają na dogłębną analizę wyników.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6">
          <div className="p-4 bg-light rounded border">
            <h2>Jak to działa?</h2>
            <p>Krok 1: Zarejestruj się i zaloguj do naszej aplikacji.</p>
            <p>Krok 2: Stwórz nową ankietę, korzystając z intuicyjnego kreatora ankiet.</p>
            <p>Krok 3: Personalizuj pytania, dodaj logotypy i kolory swojej marki.</p>
            <p>Krok 4: Udostępnij ankietę swoim respondentom poprzez link</p>
            <p>Krok 5: Analizuj zebrane dane i generuj raporty, aby uzyskać cenne wnioski.</p>
          </div>
        </div>
        <div className="col-md-6 mt-md-0 mt-3">
          <div className="p-4 bg-light rounded border">
            <h2>Opinie użytkowników</h2>
            <p><strong>Anna Kowalska:</strong> "To narzędzie znacznie ułatwiło mi zbieranie opinii od moich klientów. Interfejs jest prosty w użyciu, a analizy są bardzo pomocne."</p>
            <p><strong>Jan Nowak:</strong> "Jako nauczyciel, używam tej aplikacji do zbierania opinii od uczniów i rodziców. Jest to niezwykle wygodne i efektywne."</p>
            <p><strong>Maria Wiśniewska:</strong> "Bezpieczeństwo danych i zgodność z RODO to dla mnie kluczowe aspekty. Ta aplikacja spełnia wszystkie moje wymagania."</p>
          </div>
        </div>
      </div>
    </div>

    
  );
}

export default Home;
