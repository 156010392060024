import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import useFetch from '../useFetch';
import config from '../config';

const MySurveys = () => {
  const { token } = useAuth();
  const { data:surveys , isPending, error } = useFetch(`${config.API_BASE_URL}/ankiety/api/get_user_surveys.php`, token);

  return (
    <div className="container-sm">
        
        <div className="mt-3">
            <h2>Moje ankiety</h2>

            {isPending && <div>Ładowanie ankiet...</div>}
            {error && <div>Wystąpił błąd: {error}</div>}
            {surveys && surveys.data && surveys.data.length > 0 ? (
            <table className="table table-striped mt-4">
                <thead>
                <tr>
                    <th>Tytuł ankiety</th>
                    <th>Opis ankiety</th>
                    <th>Data utworzenia</th>
                </tr>
                </thead>
                <tbody>
                    {surveys.data.map((survey) => (
                        <tr key={survey.id}>
                            <td>
                                <Link to={`/pollresult/${survey.id}`}>{survey.tresc}</Link>
                            </td>
                            <td>{survey.description}</td>
                            <td>{new Date(survey.creation_date).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            ):(
                <div>
                    <p>Nie masz żadnych ankiet.</p>
                    <Link to="/create-survey" className="btn btn-primary">
                    Utwórz nową ankietę
                    </Link>
                </div>
            )
        }
        </div>
    </div>
  );
};

export default MySurveys;
