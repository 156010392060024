import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const AdminDashboard = () => {

    const { role } = useAuth();
    return (  
        <div className="container-sm mt-4">
            <div className="row">
                
                <div className="col-md-6 mb-4">
                    <div className="card custom-card">
                        <div className="card-body text-left">
                        <h5 className="card-title">Moje ankiety</h5>
                        <p className="mt-3">Zarządzaj swoimi ankietami</p>
                        <Link to='/my-surveys' className="stretched-link"></Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-4">
                    <div className="card custom-card">
                        <div className="card-body text-left">
                        <h5 className="card-title">Utwórz nowa ankiete</h5>
                        <p className="mt-3">Dodaj nową ankietę</p>
                        <Link to='/create-survey' className="stretched-link"></Link>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-4">
                    <div className="card custom-card">
                        <div className="card-body text-left">
                        <h5 className="card-title" >Moje konto</h5>
                        <p className="mt-3">Zarządzaj swoim kontem</p>
                        <Link to='/accountsettings' className="stretched-link"></Link>
                        </div>
                    </div>
                </div>
                {(role === 'admin') &&(
                    <>
                        <div className="col-md-6 mb-4">
                            <div className="card custom-card">
                                <div className="card-body text-left">
                                <h5 className="card-title" >Zarzadzaj użytkownikami</h5>
                                <p className="mt-3">Zarządzaj kontami użytkowników</p>
                                <Link to='/usermanagement'  className="stretched-link"></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="card custom-card">
                                <div className="card-body text-left">
                                <h5 className="card-title" >Zarzadzaj ankietami</h5>
                                <p className="mt-3">Zarządzaj wszystkimi utworzonymi ankietami </p>
                                <Link to="/surveys-management" className="stretched-link"></Link>
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </div>
      </div>
    );
}
 
export default AdminDashboard;